import React, { useEffect } from 'react';
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import SwipeableViews from 'react-swipeable-views';
import Card from 'react-bootstrap/Card';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import AcademicDetails from './AcademicDetails';
import DocumentUpload from './DocumentUpload';
import Payment from './Payment';
import Spinner from './Spinner';
import {
    ApplicationBasicDetails,
    updateBasicData,
    ApplicationData,
    checkMobileNoExistinAHSapplication,
    getInstituteforApplication,
    getCourseforApplication
} from './services';

const ageLimit40Courses = [
    "M.Sc.Child Health Nursing",
    "M.Sc.Obgy Nursing",
    "M.Sc.Community Health Nursing",
    "M.Sc.Mental Health Nursing",
    "M.Sc.Nurse Practitioner In Critical Care Nursing",
    "M.Sc.Medical Laboratory Technology In Clinical Chemistry",
    "M.Sc.Clinical Nutrition",
    "M.Sc.Medical Laboratory Technology In Pathology",
    "M.Sc.Echocardiography",
    "M.Sc.Cardiac Catheterization And Intervention",
    "M.Sc.Medical Imaging Technology",
    "M.Sc.Anaesthesia Technology",
    "M.Sc.Operation Theratre technology",
    "M.Sc.Perfusion Technology",
    "M.Sc.Emergency Medical Technology",
    "M.Sc.Trauma Care Technology",
    "M.Sc.Critical Care Technology",
    "M.Sc.Respiratory Therapy",
    "M.Sc.Renal Dialysis Technology",
    "M.Sc.Urology Technology",
    "M.Sc.Medical Laboratory Technology in Microbiology",
    "M.Sc.Haematology & Blood Transfusion",
    "M.Sc.Physician Associate",
    "M.Sc.Clinical Research",
    "M.Sc.Optometry",
    "M.Sc.Diabetic care Technology"
];

function BasicDetails() {
    const navigate = useNavigate();
    const [spinner, setspinner] = React.useState(false);
    const swipeableActionsRef = React.useRef();
    const applicationDataRef = React.useRef();
    const documentUploadDataRef = React.useRef();
    const paymentstatuysRef = React.useRef();
    const [pageIndex, setpageIndex] = React.useState(0);
    const [instituteList, setinstituteList] = React.useState([]);
    const [courseList, setcourseList] = React.useState([]);
    const [age, setage] = React.useState(0);
    const [ageAbove40, setageAbove40] = React.useState(false);
    const [BasicEditable, setBasicEditable] = React.useState(false);

    const { register,
        watch,
        handleSubmit,
        setValue: basicDetailsSetValue,
        formState: { errors }
    } = useForm({ mode: 'onBlur' });

    const Campus = watch('campus');
    const InstituteId = watch('instituteId');
    const Degree = watch('degree');
    const DateofBirth = watch('dateofbirth');
    const Course = watch('course');
    const MailId = watch('mailid');
    const MobileNumber = watch('mobileNo');
    const Name = watch('name');


    const IHPE_ValidateDynamicaly = (value) => {
        if (Course === "Masters In Health Professions Education") {
            if (!value) {
                return "This field is required"
            }
        }
    };

    var role = localStorage.getItem('role');

    useEffect(() => {
        ageCalculate();
    }, [DateofBirth])

    function getAgeAsOn31stDec() {
        const dec31st = new Date(2024, 11, 31, 23, 59, 59, 999);
        const dobDate = new Date(DateofBirth);
        const diffInMs = dec31st.getTime() - dobDate.getTime();
        const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365);

        return Math.floor(diffInYears);
    }

    function ageCalculate() {
        if (DateofBirth) {
            const ageAsOn31stDec = getAgeAsOn31stDec();
            setage(ageAsOn31stDec);
        }
    };

    useEffect(() => {
        if (Degree === "UG" && age < 17 && age > 35 && Course !== "P.B.B.Sc Nursing") {
            setageAbove40(false);

        } else if (Degree === "PG" && ageLimit40Courses.indexOf(Course) > -1 && age > 40) {
            setageAbove40(true);

        } else {
            setageAbove40(false);
        }
    }, [age, Degree, Course]);

    useEffect(() => {
        getInstituteOptions();
    }, [Campus]);

    const getInstituteOptions = async () => {
        try {
            setspinner(true);
            const result = await getInstituteforApplication({ "campus": Campus, "role": role });
            setinstituteList(result);
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (Degree && InstituteId) {
            getCourseOptions();
        }
    }, [Degree, InstituteId]);

    const getCourseOptions = async () => {
        try {
            setspinner(true);
            const result = await getCourseforApplication({ "instituteId": InstituteId, "degree": Degree, "role": role });
            if (result.length > 0) {
                setcourseList(result);
            } else {
                swal({
                    title: "Record not found",
                    icon: "info",
                });
                setcourseList([]);
            }

            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    const goToNextPage = () => {
        setpageIndex(pageIndex + 1);
    };

    const goToPreviousPage = () => {
        setpageIndex(pageIndex - 1);
    };

    async function getApplicationData(id) {
        try {
            setBasicEditable(true);
            const result = await ApplicationData({ _id: id });
            if (!result) {
                return;
            };

            basicDetailsSetValue('degree', result.degree);
            basicDetailsSetValue('district', result.district);
            basicDetailsSetValue('campus', result.campus);
            basicDetailsSetValue('course', result.course);
            basicDetailsSetValue('degree', result.degree);
            basicDetailsSetValue('degreeAwardedBy', result.degreeAwardedBy);
            basicDetailsSetValue('instituteId', result.instituteId);
            basicDetailsSetValue('mobileNo', result.mobileNo);
            basicDetailsSetValue('name', result.name);
            basicDetailsSetValue('nationality', result.nationality);
            basicDetailsSetValue('fatherName', result.fatherName);
            basicDetailsSetValue('gender', result.gender);
            basicDetailsSetValue('dateofbirth', result.dateofbirth);
            basicDetailsSetValue('addressLine1', result.addressLine1);
            basicDetailsSetValue('addressLine2', result.addressLine2);
            basicDetailsSetValue('addressLine3', result.addressLine3);
            basicDetailsSetValue('addressLine4', result.addressLine4);
            basicDetailsSetValue('district', result.district);
            basicDetailsSetValue('state', result.state);
            basicDetailsSetValue('pincode', result.pincode);
            basicDetailsSetValue('religion', result.religion);
            basicDetailsSetValue('community', result.community);
            basicDetailsSetValue('weakerSection', result.weakerSection);
            basicDetailsSetValue('aadharNo', result.aadharNo);
            basicDetailsSetValue('mobileNo', result.mobileNo);
            basicDetailsSetValue('mailid', result.mailid);
            basicDetailsSetValue('officialmailid', result.officialmailid);
            basicDetailsSetValue('officialphoneno', result.officialphoneno);
            basicDetailsSetValue('officialaddress', result.officialaddress);
            basicDetailsSetValue('anyOtherHPECourseCompleted', result.anyOtherHPECourseCompleted);
            basicDetailsSetValue('institute', result.institute);
            basicDetailsSetValue('regular_later', result.regular_later);
            setage(result.age);
            localStorage.setItem('institute', result.institute);
            documentUploadDataRef.current = result.uploadDocuments;
            paymentstatuysRef.current = result.paymentStatus;
            applicationDataRef.current = result;
        }
        catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const applicationEditId = localStorage.getItem('_id');
        if (applicationEditId) {
            getApplicationData(applicationEditId);
        }
    }, []);

    const logout = () => {
        localStorage.clear();
        navigate("/");
    };

    const goToDashboard = () => {
        localStorage.removeItem("_id");
        localStorage.removeItem("institute");
        navigate("/dashboard");
    };

    const updateSwipeableViewHeight = () => {
        if (swipeableActionsRef.current) {
            swipeableActionsRef.current.updateHeight();
        }
    };

    React.useEffect(() => {
        updateSwipeableViewHeight();
    }, [age, ageAbove40, Course]);

    const submit = async (data) => {
        try {
            setspinner(true);
            if (!BasicEditable) {
                const exist = await checkMobileNoExistinAHSapplication({ "MobileNo": MobileNumber, "Course": Course, "MailId": MailId });
                if (exist.length > 0) {
                    swal({
                        title: "Mobile number, Mail id and Course is already exist",
                        icon: "error",
                        dangerMode: true,
                    });
                } else {
                    data.userName = localStorage.getItem('username');
                    data.age = age;
                    const result = await ApplicationBasicDetails(data);
                    setBasicEditable(true);
                    localStorage.setItem("_id", result._id);
                    localStorage.setItem('institute', result.institute);
                    goToNextPage();
                }
            } else {
                data.age = age;
                await updateBasicData({ data, _id: localStorage.getItem('_id'), });
                goToNextPage();
            }
            setspinner(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Row className='mb-3' style={{ width: '100%' }}>
                    <Col>
                        <Button variant="primary" type="button"
                            style={{ marginTop: '3px', marginLeft: '10px' }}
                            onClick={goToDashboard}
                        >
                            Go To Dashboard
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'end' }}>
                        <Button variant="primary" type="button"
                            style={{ marginTop: '3px', marginRight: 0 }}
                            onClick={logout}
                        >
                            Logout
                        </Button>
                    </Col>
                </Row>
            </Container>

            <SwipeableViews index={pageIndex} animateHeight action={(actions) => (swipeableActionsRef.current = actions)}>
                <Card style={{ width: "80%", marginLeft: "10%", marginRight: "10%", paddingBottom: "50px", height: "auto" }}>
                    <h3 style={{ textAlign: 'center', color: '#fd7e14', paddingTop: '10px' }}>
                        Application for Admission to the Academic Year 2024 - 2025</h3>
                    <Container>
                        <Form onSubmit={handleSubmit(submit)}>
                            {(BasicEditable === false || role === "admin") &&
                                <div>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Select Campus</Col>
                                        <Col xs={12} sm={6}>

                                            <Form.Select
                                                {...register('campus', {
                                                    required: "Please select campus"
                                                })}
                                            >
                                                <option value="">Select campus</option>
                                                <option value="Pondicherry Campus">Pondicherry Campus</option>
                                                <option value="Chennai Campus">Chennai Campus</option>
                                                <option value="Karaikal Campus">Karaikal Campus</option>
                                            </Form.Select>
                                            {
                                                errors?.campus &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.campus.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Select Institute</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select
                                                {...register('instituteId', {
                                                    required: "Please select institute"
                                                })}
                                            >
                                                <option>Select Institute</option>
                                                {instituteList.map((obj) => (
                                                    <option
                                                        key={obj._id}
                                                        value={obj.instituteId}>
                                                        {obj.institute}
                                                    </option>
                                                ))
                                                }
                                            </Form.Select>
                                            {
                                                errors?.instituteId &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.instituteId.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6} >Select Degree</Col>
                                        <Col xs={12} sm={6} >
                                            <Form.Group>
                                                <Form.Check
                                                    inline
                                                    label="Under Graduate"
                                                    type="radio"
                                                    value="UG"
                                                    {...register('degree', { required: "Please select degree" })}
                                                >
                                                </Form.Check>
                                                <Form.Check
                                                    inline
                                                    label="Post Graduate"
                                                    type="radio"
                                                    value="PG"
                                                    {...register('degree', { required: "Please select degree" })}
                                                >
                                                </Form.Check>
                                            </Form.Group>
                                            {
                                                errors?.degree &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.degree.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Select Course</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select
                                                {...register('course', {
                                                    required: "Please select course"
                                                })}
                                            >
                                                <option>Select Course</option>
                                                {courseList.map((obj) => (
                                                    <option
                                                        key={obj._id}
                                                        value={obj.courseName}>
                                                        {obj.courseName}
                                                    </option>
                                                ))
                                                }
                                            </Form.Select>
                                            {
                                                errors?.course &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.course.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Are you a regular or lateral entry?</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Check
                                                    inline
                                                    label="Regular"
                                                    type="radio"
                                                    value="Regular"
                                                    {...register('regular_later', { required: "Please select" })}
                                                >
                                                </Form.Check>
                                                <Form.Check
                                                    inline
                                                    label="Lateral Entry"
                                                    type="radio"
                                                    value="Lateral Entry"
                                                    {...register('regular_later', { required: "Please select" })}
                                                >
                                                </Form.Check>
                                            </Form.Group>
                                            {
                                                errors?.regular_later &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.regular_later.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Name <br></br>
                                            <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as per previous academic certificate)</span>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                {...register('name', { required: "Name field is empty." })}
                                            >
                                            </Form.Control>
                                            {
                                                errors?.name &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.name.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Name of Father / Guardian</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                {...register('fatherName', { required: "Father name field is empty." })}
                                            >
                                            </Form.Control>
                                            {
                                                errors?.fatherName &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.fatherName.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            }

                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Gender</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="Male"
                                            type="radio"
                                            value="Male"
                                            {...register('gender', { required: "Please select gender" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Female"
                                            type="radio"
                                            value="Female"
                                            {...register('gender', { required: "Please select gender" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="Third Gender"
                                            type="radio"
                                            value="Third Gender"
                                            {...register('gender', { required: "Please select gender" })}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                    {
                                        errors?.gender &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.gender.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Date of Birth</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='Date'
                                        {...register('dateofbirth', { required: "Date of birth field is empty." })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.dateofbirth &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.dateofbirth.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Age <br></br>
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as on 31 December)</span>
                                </Col>
                                {
                                    age > 0 && <Col>{age}</Col>
                                }
                            </Row>
                            {age < 17 && age !== 0 && Degree === "UG" &&
                                <Row>
                                    <Col></Col>
                                    <Col xs={12} sm={6}><span style={{ color: 'red' }}>
                                        Candidate should be completing 17 years as on 31st Dec. 2024.</span></Col>
                                </Row>
                            }
                            {age > 35 && Degree === "UG" && Course !== "P.B.B.Sc Nursing" &&
                                <Row>
                                    <Col></Col>
                                    <Col xs={12} sm={6}><span style={{ color: 'red' }}>
                                        Upper age Limit 35 years.</span></Col>
                                </Row>
                            }
                            {
                                ageAbove40 && (
                                    <Row>
                                        <Col></Col>
                                        <Col xs={12} sm={6}><span style={{ color: 'red' }}>Upper age Limit 40 Years For {Course} </span></Col>
                                    </Row>
                                )
                            }
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>House Number / Appartment Name</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('addressLine1', { required: "Please enter your house number or appartment name" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.addressLine1 &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.addressLine1.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Street</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('addressLine2', { required: "Please enter your street name" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.addressLine2 &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.addressLine2.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Locality</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('addressLine3', { required: "Please enter your locality" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.addressLine3 &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.addressLine3.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Taluk / Nagar</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('addressLine4', { required: "Please enter your taluk or nagar" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.addressLine4 &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.addressLine4.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>District</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('district', { required: "Please enter your district" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.district &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.district.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>State</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='state'
                                        {...register('state', { required: "Please enter your state" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.state &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.state.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Pin Code</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('pincode', { required: "Please enter your pincode" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.pincode &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.pincode.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Nationality</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        {...register('nationality', { required: "Please enter your nationality" })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.nationality &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.nationality.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Select Religion</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Select
                                        {...register('religion', {
                                            required: "Please select religion"
                                        })}
                                    >
                                        <option value="">Select Religion</option>
                                        <option value="Hindu">Hindu</option>
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                        <option value="Buddhist">Buddhist</option>
                                        <option value="Jain">Jain</option>
                                        <option value="Sikh">Sikh</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>
                                    {
                                        errors?.religion &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.religion.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Community</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="SC"
                                            type="radio"
                                            value="SC"
                                            {...register('community', { required: "Please select community" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="ST"
                                            type="radio"
                                            value="ST"
                                            {...register('community', { required: "Please select community" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="OBC"
                                            type="radio"
                                            value="OBC"
                                            {...register('community', { required: "Please select community" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="MBC"
                                            type="radio"
                                            value="MBC"
                                            {...register('community', { required: "Please select community" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="BC"
                                            type="radio"
                                            value="BC"
                                            {...register('community', { required: "Please select community" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="BC (M)"
                                            type="radio"
                                            value="BC (M)"
                                            {...register('community', { required: "Please select community" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="OC"
                                            type="radio"
                                            value="OC"
                                            {...register('community', { required: "Please select community" })}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                    {
                                        errors?.community &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.community.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Economically Weaker Section<br />
                                    <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(Family Income Below Taxable Limit)</span>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label="YES"
                                            type="radio"
                                            value="YES"
                                            {...register('weakerSection', { required: "Economically Weaker Section field is required" })}
                                        >
                                        </Form.Check>
                                        <Form.Check
                                            inline
                                            label="NO"
                                            type="radio"
                                            value="NO"
                                            {...register('weakerSection', { required: "Economically Weaker Section field is required" })}
                                        >
                                        </Form.Check>
                                    </Form.Group>
                                    {
                                        errors?.weakerSection &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.weakerSection.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Aadhar No</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='text'
                                        name='aadharNo'
                                        {...register('aadharNo', {
                                            required: "Aadhar number Field is Empty",
                                            pattern: {
                                                value: /^\d{4}\s\d{4}\s\d{4}$/,
                                                message: "Please Enter a Aadhar number in the format 1111 2222 3333"
                                            }
                                        })}
                                    >
                                    </Form.Control>
                                    {
                                        errors?.aadharNo &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.aadharNo.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            {(BasicEditable === false || role === "admin") &&
                                <Row className="mt-3">
                                    <Col xs={12} sm={6}>Mobile No</Col>
                                    <Col xs={12} sm={6}>
                                        <InputGroup>
                                            <InputGroup.Text id="mobile-prefix">+91</InputGroup.Text>
                                            <Form.Control
                                                name='mobileNo'
                                                type="tel"
                                                {
                                                ...register('mobileNo',
                                                    {
                                                        required: "Mobile Number Field is Empty",
                                                        pattern: {
                                                            value: /^[0-9]{10}$/,
                                                            message: "Please Enter a Valid 10 Digit Phone Number"
                                                        }
                                                    }
                                                )
                                                }
                                            >
                                            </Form.Control>
                                        </InputGroup>
                                        {
                                            errors?.mobileNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.mobileNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                            }
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>Mail Id</Col>
                                <Col xs={12} sm={6}>
                                    <Form.Control
                                        type='email'
                                        {
                                        ...register('mailid',
                                            {
                                                required: "Mail Id Field is Empty",
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.+com$/,
                                                    message: "Please Enter a Valid Email Address"
                                                }
                                            }
                                        )
                                        }
                                    >
                                    </Form.Control>
                                    {
                                        errors?.mailid &&
                                        <Form.Text className="text-error" style={{ color: "red" }}>
                                            {errors.mailid.message}
                                        </Form.Text>
                                    }
                                </Col>
                            </Row>
                            {Course === "Masters In Health Professions Education" &&
                                <div>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Official Mail Id</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='email'
                                                {
                                                ...register('officialmailid',
                                                    { validate: IHPE_ValidateDynamicaly }
                                                )
                                                }
                                            >
                                            </Form.Control>
                                            {
                                                errors?.officialmailid &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.officialmailid.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Official Phone No</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='officialphoneno'
                                                {
                                                ...register('officialphoneno',
                                                    { validate: IHPE_ValidateDynamicaly }
                                                )
                                                }
                                            >
                                            </Form.Control>
                                            {
                                                errors?.officialphoneno &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.officialphoneno.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Official / Institutional Address</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Control
                                                type='text'
                                                name='officialaddress'
                                                {...register('officialaddress', { validate: IHPE_ValidateDynamicaly })}
                                            >
                                            </Form.Control>
                                            {
                                                errors?.officialaddress &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.officialaddress.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={12} sm={6}>Any other HPE Related Course completed earlier?</Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    type="radio"
                                                    value="Yes"
                                                    {...register('anyOtherHPECourseCompleted', { validate: IHPE_ValidateDynamicaly })}
                                                >
                                                </Form.Check>
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    type="radio"
                                                    value="No"
                                                    {...register('anyOtherHPECourseCompleted', { validate: IHPE_ValidateDynamicaly })}
                                                >
                                                </Form.Check>
                                            </Form.Group>
                                            {
                                                errors?.anyOtherHPECourseCompleted &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.anyOtherHPECourseCompleted.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row style={{ textAlign: 'center' }}>
                                <Col>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        style={{ alignItems: 'center' }}
                                        className="mt-3 w-70"
                                    >
                                        Save & Next
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Card>
                <div>
                    <AcademicDetails
                        goToNextPage={goToNextPage}
                        goToPreviousPage={goToPreviousPage}
                        updateSwipeableViewHeight={updateSwipeableViewHeight}
                        selectedDegree={Degree}
                        selectedCourse={Course}
                        applicationData={applicationDataRef.current}
                    />
                </div>

                <div>
                    <DocumentUpload
                        updateSwipeableViewHeight={updateSwipeableViewHeight}
                        documentUploadData={documentUploadDataRef.current}
                        goToPreviousPage={goToPreviousPage}
                        goToNextPage={goToNextPage}
                        paymentStatus={paymentstatuysRef.current}
                        selectedInstitute={localStorage.getItem('institute')}
                    />
                </div>

                <div>
                    <Payment
                        updateSwipeableViewHeight={updateSwipeableViewHeight}
                        selectedDegree={Degree}
                        selectedCourse={Course}
                        selectedInstitute={localStorage.getItem('institute')}
                        name={Name}
                    />
                </div>
            </SwipeableViews>
        </React.Fragment >
    )
}

export default BasicDetails