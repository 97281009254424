import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Card, Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { List, Divider } from 'antd';
import { MortarboardFill, Upload, Download } from 'react-bootstrap-icons';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import Spinner from './Spinner';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import {
    studentRegistration_BPT_MPT_CheckDuplicateReg,
    studentRegistration_BPT_MPT,
    studentRegistration_BPT_MPT_DocUpload,
    studentRegistration_BPT_MPT_UploadedDocList,
    studentRegistration_BPT_MPT_DeleteDoc,
    studentRegistration_BPT_MPT_DownloadDeclaration,
} from './services';

function StudentRegistration_BPT_MPT() {
    const [docname, Setdocname] = React.useState('');
    const [docList, setDocList] = React.useState([]);
    const [spinner, setspinner] = React.useState(false);
    const [age, setage] = React.useState(0);
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
    const formWizardRef = React.createRef();
    const [doc, setdoc] = React.useState();
    const [dataSaved, setDataSaved] = React.useState(false);
    const fileInputRef = React.useRef(null);
    const fileNameRef = React.useRef(null);
    const navigate = useNavigate();
    const [TotalObtained, setTotalObtained] = React.useState(0);
    const [TotalMax, setTotalMax] = React.useState(0);
    const [TotalPercent, setTotalPercent] = React.useState(0);
    const [schoolExamData, setschoolExamData] = React.useState([{ subject: '', obtainedMarks: 0, maximumMarks: 0, percentage: 0 }])
    const declarationFormList = [
        "Application form",
        "Joint undertaking by the student and parent / guardian",
        "Anti ragging - undertaking reference no",
        "Hostel Application Form"
    ]

    const {
        register,
        watch,
        control,
        trigger,
        setValue,
        formState: { errors, isValid }
    } = useForm({ mode: 'onBlur' });

    const wholeData = watch();
    const dateOfBirth = watch('dateofbirth');
    const name = watch('name');
    const mobileNo = watch('mobileNo');
    const programme = watch('programme');
    const institute = watch('institute');
    const residentialStatus = watch('residentialStatus');

    React.useEffect(() => {
        const DocUpdate = localStorage.getItem("updateDoc");
        if (DocUpdate) {
            formWizardRef.current?.goToTab(2);
        }
    }, []);

    React.useEffect(() => {
        if (programme === "BPT") {
            setValue('specialisation', '');
        }
    }, [programme])

    const specialisationValidateDynamicaly = (value) => {
        if (programme === "MPT") {
            if (!value) {
                return "Specialisation Field is Empty"
            }
        }
    };

    const ugValidateDynamicaly = (value) => {
        if (programme === "MPT") {
            if (!value) {
                return "This field is required"
            }
        }
    };

    function GraduationCapIcon() {
        return (
            <MortarboardFill />
        );
    };

    function UploadIcon() {
        return (
            <Upload />
        );
    };

    function DownloadIcon() {
        return (
            <Download />
        );
    };
    const tabChanged = async ({ prevIndex, nextIndex }) => {
        setCurrentTabIndex(prevIndex);
    };

    const handelPrev = () => {
        const DocUpdate = localStorage.getItem("updateDoc");
        if (!DocUpdate) {
            formWizardRef.current?.prevTab();
        }

        if (DocUpdate && currentTabIndex > 2) {
            formWizardRef.current?.prevTab();
        }
    };

    const handelNext = async () => {

        if (currentTabIndex === 0 && !dataSaved) {
            if (name && mobileNo && programme && institute) {
                var obj = {
                    "institute": institute,
                    "name": name,
                    "programme": programme,
                    "mobileNo": mobileNo
                };
                var result = await studentRegistration_BPT_MPT_CheckDuplicateReg(obj);
                if (result.length > 0) {
                    swal({
                        title: "Data already exist.",
                        icon: 'info'
                    });
                } else {
                    formWizardRef.current?.nextTab();
                    await trigger(['programme', 'specialisation', 'name', 'dateofbirth', 'placeofbirth', 'district',
                        'antiRaggingNo', 'annualincome', 'communicationaddress', 'community', 'fatherName', 'fatheroccupation',
                        'firstgraduate', 'gender', 'mailId', 'mobileNo', 'motheroccupation', 'nationality', 'panno',
                        'permanentaddress', 'state', 'aadharno', 'residentialStatus', "fatherMobileNo", "abcid", "motherMobileNo", "busStops"
                    ]);
                }
            } else {
                swal({
                    title: "Please fill all required fields.",
                    icon: 'info'
                });
            }
        };

        if (currentTabIndex === 1 && !dataSaved) {
            if (isValid) {
                submit();
                formWizardRef.current?.nextTab();
            } else {
                swal({
                    title: "Please fill all required fields and check both two pages",
                    icon: 'info'
                });
                await trigger(['hsc_Month_year_of_passing', 'hsc_Name_address', 'hsc_Total_percentage_of_marks', 'hsc_Attempt', 'overseasstudentgrade',
                    'schoolExamData', 'ugDegreeData', 'totalMarksObtained', 'totalmaximumMarks', 'totalPercentage', 'declaration', "fatherMobileNo", "abcid", "motherMobileNo"
                ]);
            }
        };

        if ((currentTabIndex === 0 && dataSaved) || (currentTabIndex === 1 && dataSaved)) {
            formWizardRef.current?.nextTab();
        }

        if (currentTabIndex > 1) {
            formWizardRef.current?.nextTab();
        }

    };

    const submit = async () => {
        setspinner(true);
        wholeData.userName = localStorage.getItem("username");
        wholeData.age = age;
        wholeData.schoolExamData = schoolExamData;
        wholeData.TotalObtained = TotalObtained;
        wholeData.TotalMax = TotalMax;
        wholeData.TotalPercent = TotalPercent;
        const result = await studentRegistration_BPT_MPT(wholeData);
        localStorage.setItem("id", result.id);
        setDataSaved(true);
        setspinner(false);
    };

    function getAgeAsOn31stDec() {
        const dec31st = new Date(2024, 11, 31, 23, 59, 59, 999);
        const dobDate = new Date(dateOfBirth);
        const diffInMs = dec31st.getTime() - dobDate.getTime();
        const diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365);
        return Math.floor(diffInYears);
    };
    React.useEffect(() => {
        const ageAsOn31stDec = getAgeAsOn31stDec();
        setage(ageAsOn31stDec);
    }, [dateOfBirth]);

    const goToDashboard = () => {
        localStorage.removeItem("id");
        localStorage.removeItem("updateDoc");
        navigate("/dashboard");
    };

    const docnameChange = (event) => {
        Setdocname(event.target.value);
    };

    const {
        fields: UG_Details,
        append: appendUG_DegreeItems,
        remove: removeUG_DegreeItems
    } = useFieldArray(
        {
            control,
            name: 'ugDegreeData'
        }
    );

    if (UG_Details.length === 0) {
        appendUG_DegreeItems();
    };

    const handleFileChange = async (e, index) => {
        const file = e.target.files[0];
        if (file) {
            setdoc(file);
        }
        else {
            swal({
                title: "Please Choose File",
                icon: 'info'
            });
        }
    };

    const viewDocument = async (obj) => {
        if (obj.filePath) {
            window.open(process.env.REACT_APP_SERVICE_URL + "/documents/" + obj.filePath, "_blank");
        } else {
            swal({
                title: "File Not Found.",
                icon: 'info'
            });
        }
    };

    const deleteDocument = async (obj) => {
        setspinner(true);
        var object = {
            "_id": localStorage.getItem("id"),
            "docId": obj._id
        }
        await studentRegistration_BPT_MPT_DeleteDoc(object);
        setspinner(false);
        getUploadedDocuments();
    };

    const docUpload = async () => {
        if (docname && doc) {
            setspinner(true);
            const formData = new FormData();
            formData.append('documentName', docname);
            formData.append('file', doc);
            formData.append('id', localStorage.getItem("id"));
            await studentRegistration_BPT_MPT_DocUpload(formData);
            setspinner(false);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            if (fileNameRef.current) {
                fileNameRef.current.value = 'Select Document Name';
            }

            Setdocname('');
            setdoc(null);

            getUploadedDocuments();

        } else {
            swal({
                title: "Please Choose File and File Name",
                icon: 'info'
            });
        }
    };

    React.useEffect(() => {
        getUploadedDocuments();
    }, []);

    const getUploadedDocuments = async () => {
        const ID = localStorage.getItem("id");
        if (ID) {
            setspinner(true);
            const result = await studentRegistration_BPT_MPT_UploadedDocList({ "id": ID });
            setDocList(result.uploadDocuments);
            setspinner(false);
        }
    };

    const downloadAllDeclarationsInPDF = async () => {
        try {
            setspinner(true);
            const response = await studentRegistration_BPT_MPT_DownloadDeclaration({ "_id": localStorage.getItem('id') });
            const blob = new Blob([response], { type: 'application/zip' });
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `declarations.zip`); // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setspinner(false);
            localStorage.removeItem("id");
            localStorage.removeItem("updateDoc");
            navigate("/dashboard");
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        let Tobtain = 0;
        let Tmax = 0;

        schoolExamData.forEach((obj) => {
            if (obj.obtainedMarks !== 0 && obj.maximumMarks !== 0) {
                Tobtain += parseInt(obj.obtainedMarks);
                Tmax += parseInt(obj.maximumMarks);
            }
        });

        setTotalMax(Tmax);
        setTotalObtained(Tobtain);
        setTotalPercent(parseInt(Tmax !== 0 ? (Tobtain / Tmax) * 100 : 0).toFixed(2));
    }, [schoolExamData]);

    const handleInputChange = (event, index) => {
        const { target: { name, value } } = event;

        const copyData = [...schoolExamData];
        const data = copyData[index];

        data[name] = value;

        const { maximumMarks, obtainedMarks } = data;
        if (maximumMarks && obtainedMarks) {
            data.percentage = ((obtainedMarks / maximumMarks) * 100).toFixed(2);
        }

        setschoolExamData(copyData);
    };

    const appendExaminationItems = () => {
        const newHSC = [...schoolExamData, { subject: '', obtainedMarks: 0, maximumMarks: 0, percentage: 0 }];
        setschoolExamData(newHSC);
    }

    const removeExaminationItems = (index) => {
        const copyHSC = [...schoolExamData];
        copyHSC.splice(index, 1);
        setschoolExamData(copyHSC);
    };

    const getProgramme = () => {
        if (institute === "School of Physiotherapy - SOPT") {
            return (
                <React.Fragment>
                    <option key="0" value="">Please Select</option>
                    <option key="1" value="BPT">BPT</option>
                    <option key="2" value="MPT">MPT</option>
                </React.Fragment>
            )
        };
        if (institute === "School of Biomedical Sciences - SBMS") {
            return (
                <React.Fragment>
                    <option key="0" value="">Please Select</option>
                    <option key="1" value="Biomedical Sciences">Biomedical Sciences</option>
                    <option key="2" value="M.Sc">M.Sc</option>
                    <option key="3" value="M.Sc+Ph.D">M.Sc+Ph.D</option>
                </React.Fragment>
            )
        };
    };

    React.useEffect(() => {
        setValue('programme', '');
    }, [institute])

    return (
        <>
            <div>
                {spinner === true &&
                    <Spinner></Spinner>
                }
            </div>
            <Container>
                <Card className='mb-4' style={{ with: "80%" }}>
                    <Form>
                        <FormWizard
                            stepSize="sm"
                            onTabChange={tabChanged}
                            ref={formWizardRef}
                            backButtonTemplate={() => (
                                <button
                                    className="back-button"
                                    type='button'
                                    onClick={handelPrev}
                                >
                                    back
                                </button>
                            )}
                            nextButtonTemplate={() => (
                                <button
                                    className="next-button"
                                    type='button'
                                    onClick={handelNext}
                                >
                                    next
                                </button>
                            )}

                            finishButtonTemplate={() => (
                                <button
                                    className="finish-button"
                                    type='button'
                                    onClick={goToDashboard}
                                >
                                    finish
                                </button>
                            )}
                        >

                            <FormWizard.TabContent
                                title="Personal details"
                                icon="ti-user"
                            >
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Institute</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Select
                                            {...register('institute', {
                                                required: "Please select institute"
                                            })}
                                        >
                                            <option value="">Please Select</option>
                                            <option value="School of Physiotherapy - SOPT">School of Physiotherapy - SOPT</option>
                                            <option value="School of Biomedical Sciences - SBMS">School of Biomedical Sciences - SBMS</option>
                                        </Form.Select>
                                        {
                                            errors?.institute &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.institute.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Programme</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Select
                                            {...register('programme', {
                                                required: "Please select programme"
                                            })}
                                        >
                                            {getProgramme()}
                                        </Form.Select>
                                        {
                                            errors?.programme &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.programme.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                {programme === "MPT" &&
                                    <Row style={{ textAlign: "start" }} className='mt-2'>
                                        <Col xs={12} sm={6}><strong>Specialisation</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select

                                                {...register('specialisation', { validate: specialisationValidateDynamicaly })}
                                            >
                                                <option value="">Please Select</option>
                                                <option value="M.P.T. (Master of Physiotherapy) - Orthopaedic Conditions">M.P.T. (Master of Physiotherapy) - Orthopaedic Conditions</option>
                                                <option value="M.P.T. (Master of Physiotherapy) - Neurological and Psychosomatic disorders">M.P.T. (Master of Physiotherapy) - Neurological and Psychosomatic disorders</option>
                                                <option value="M.P.T. (Master of Physiotherapy) - Cardio Vascular & Pulmunory Conditions">M.P.T. (Master of Physiotherapy) - Cardio Vascular & Pulmunory Conditions</option>
                                                <option value="M.P.T. (Master of Physiotherapy) - Sports Physiotherapy">M.P.T. (Master of Physiotherapy) - Sports Physiotherapy</option>
                                            </Form.Select>
                                            {
                                                errors?.specialisation &&
                                                <Form.Text className="text-error" style={{ color: "red" }}>
                                                    {errors.specialisation.message}
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Row>
                                }
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Name of the Student</strong><br />
                                        <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as in School Certificate)</span>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('name', { required: "Name field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.name &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.name.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Date of Birth</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='Date'
                                            {...register("dateofbirth", { required: "Please Select Your Date of Birth", pattern: /^\d{4}-\d{2}-\d{2}$/ })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.dateofbirth &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.dateofbirth.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Age</strong><br />
                                        <span style={{ fontSize: '15px', fontStyle: 'italic' }}>(as on 31 December)</span>
                                    </Col>
                                    <Col xs={12} sm={6}>{age}</Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Place of Birth</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('placeofbirth', { required: "Place of birth field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.placeofbirth &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.placeofbirth.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>District</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('district', { required: "District field is empty." })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.district &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.district.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Nationality</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('nationality', { required: "Nationality Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.nationality &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.nationality.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>State</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            {...register('state', { required: "state Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.state &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.state.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Community</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group>
                                            <Form.Check
                                                inline
                                                label="SC"
                                                type="radio"
                                                value="SC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="ST"
                                                type="radio"
                                                value="ST"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="MBC"
                                                type="radio"
                                                value="MBC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="BC"
                                                type="radio"
                                                value="BC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="BC (M)"
                                                type="radio"
                                                value="BC (M)"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="OBC"
                                                type="radio"
                                                value="OBC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="OC"
                                                type="radio"
                                                value="OC"
                                                {...register('community', { required: "Please Select Community" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.community &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.community.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Name of Father / Guardian</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='fatherName'
                                            {...register('fatherName', { required: "Father / Guardian Name Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.fatherName &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.fatherName.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Name of the Mother</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='motherName'
                                            {...register('motherName', { required: "Mother Name Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.motherName &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.motherName.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Permanent Address</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            as="textarea"
                                            {...register('permanentaddress', { required: "Permanent Address Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.permanentaddress &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.permanentaddress.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Communication Address</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            as="textarea"
                                            {...register('communicationaddress', { required: "Communication Address Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.communicationaddress &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.communicationaddress.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mobile Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type="number"
                                            {
                                            ...register('mobileNo',
                                                {
                                                    required: "Mobile Number Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: "Please Enter a Valid 10 Digit Phone Number"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.mobileNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.mobileNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mail Id</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='email'
                                            {
                                            ...register('mailId',
                                                {
                                                    required: "Mail Id Field is Empty",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.+com$/,
                                                        message: "Please Enter a Valid Email Address"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.mailId &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.mailId.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Gender</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group
                                        >
                                            <Form.Check
                                                inline
                                                label="Male"
                                                type="radio"
                                                value="Male"
                                                {...register('gender', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Female"
                                                value="Female"
                                                type="radio"
                                                {...register('gender', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Third Gender"
                                                type="radio"
                                                value="Third Gender"
                                                {...register('gender', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.gender &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.gender.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>


                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Are you the first graduate in your family</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group
                                        >
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                type="radio"
                                                value="Yes"
                                                {...register('firstgraduate', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="No"
                                                value="No"
                                                type="radio"
                                                {...register('firstgraduate', { required: "Please Select Gender" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.firstgraduate &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.firstgraduate.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Father Occupation</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='fatherOccupation'
                                            {...register('fatheroccupation', { required: "Father Occupation Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.fatheroccupation &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.fatheroccupation.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Father's Mobile Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type="number"
                                            {
                                            ...register('fatherMobileNo',
                                                {
                                                    required: "Mobile Number Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: "Please Enter a Valid 10 Digit Phone Number"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.fatherMobileNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.fatherMobileNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mother Occupation</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='motherOccupation'
                                            {...register('motheroccupation', { required: "Mother Occupation Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.motheroccupation &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.motheroccupation.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Mother's Mobile Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type="number"
                                            {
                                            ...register('motherMobileNo',
                                                {
                                                    required: "Mobile Number Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: "Please Enter a Valid 10 Digit Phone Number"
                                                    }
                                                }
                                            )
                                            }
                                        >
                                        </Form.Control>
                                        {
                                            errors?.motherMobileNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.motherMobileNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>


                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Annual Family Income</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='number'
                                            name='annualincome'
                                            {...register('annualincome', { required: "Annual Income Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.annualincome &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.annualincome.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Aadhar number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='aadharno'
                                            {...register('aadharno', {
                                                required: "Aadhar number Field is Empty",
                                                pattern: {
                                                    value: /^\d{4}\s\d{4}\s\d{4}$/,
                                                    message: "Please Enter a Aadhar number in the format 1111 2222 3333"
                                                }
                                            })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.aadharno &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.aadharno.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Pan number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='panno'
                                            {...register('panno', { required: "Pan number Field is Empty" })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.panno &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.panno.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Anti Ragging Reference Number</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='number'
                                            name='antiRaggingNo'
                                            {...register('antiRaggingNo',
                                                {
                                                    required: "Anti Ragging Reference Number Field is Empty",
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Negative value not allowed"
                                                    }
                                                })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.antiRaggingNo &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.antiRaggingNo.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>ABC id</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Control
                                            type='text'
                                            name='abcid'
                                            {...register('abcid', {
                                                required: "ABC id field is empty",
                                                pattern: {
                                                    value: /^\d{4}\s\d{4}\s\d{4}$/,
                                                    message: "Please Enter a ABC id number in the format 1111 2222 3333"
                                                }
                                            })}
                                        >
                                        </Form.Control>
                                        {
                                            errors?.abcid &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.abcid.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ textAlign: "start" }} className='mt-2'>
                                    <Col xs={12} sm={6}><strong>Residential Status</strong></Col>
                                    <Col xs={12} sm={6}>
                                        <Form.Group
                                        >
                                            <Form.Check
                                                inline
                                                label="Hostel"
                                                type="radio"
                                                value="Hostel"
                                                {...register('residentialStatus', { required: "Please select your residential status" })}
                                            >
                                            </Form.Check>
                                            <Form.Check
                                                inline
                                                label="Day Scholar"
                                                value="Day Scholar"
                                                type="radio"
                                                {...register('residentialStatus', { required: "Please select your residential status" })}
                                            >
                                            </Form.Check>
                                        </Form.Group>
                                        {
                                            errors?.residentialStatus &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.residentialStatus.message}
                                            </Form.Text>
                                        }
                                    </Col>
                                </Row>

                                {residentialStatus === "Day Scholar" &&
                                    <Row style={{ textAlign: "start" }} className='mt-2'>
                                        <Col xs={12} sm={6}><strong>If you need transport, select a bus stop</strong></Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Select
                                                {...register('busStops')}
                                            >
                                                <option>Select Bus Stop</option>
                                                <option name="busStops" value="OT Manikoondu (Cuddalore)">OT Manikoondu (Cuddalore)</option>
                                                <option name="busStops" value="Committee (Cuddalore)">Committee (Cuddalore)</option>
                                                <option name="busStops" value="KV Tex (Cuddalore)">KV Tex (Cuddalore)</option>
                                                <option name="busStops" value="GRT (Cuddalore)">GRT (Cuddalore)</option>
                                                <option name="busStops" value="New Cinemas (Cuddalore)">New Cinemas (Cuddalore)</option>
                                                <option name="busStops" value="Post Office (Cuddalore)">Post Office (Cuddalore)</option>
                                                <option name="busStops" value="G.H. (Cuddalore)">G.H. (Cuddalore)</option>
                                                <option name="busStops" value="Blind School (Cuddalore)">Blind School (Cuddalore)</option>
                                                <option name="busStops" value="KNC (Cuddalore)">KNC (Cuddalore)</option>
                                                <option name="busStops" value="Semandalam (Cuddalore)">Semandalam (Cuddalore)</option>
                                                <option name="busStops" value="Dhanalakshmi Nagar (Cuddalore)">Dhanalakshmi Nagar (Cuddalore)</option>
                                                <option name="busStops" value="Gundu Salai (Cuddalore)">Gundu Salai (Cuddalore)</option>
                                                <option name="busStops" value="Aalpettai (Cuddalore)">Aalpettai (Cuddalore)</option>
                                                <option name="busStops" value="Gankanakuppam (Cuddalore)">Gankanakuppam (Cuddalore)</option>
                                                <option name="busStops" value="Aarupadai Hostel (Cuddalore)">Aarupadai Hostel (Cuddalore)</option>
                                                <option name="busStops" value="Gorimedu">Gorimedu</option>
                                                <option name="busStops" value="Subbaiya">Subbaiya</option>
                                                <option name="busStops" value="Thattanchavdy">Thattanchavdy</option>
                                                <option name="busStops" value="Estate">Estate</option>
                                                <option name="busStops" value="Rajiv Gandhi">Rajiv Gandhi</option>
                                                <option name="busStops" value="Indira Gandhi">Indira Gandhi</option>
                                                <option name="busStops" value="Nellithope">Nellithope</option>
                                                <option name="busStops" value="New Bus Stand">New Bus Stand</option>
                                                <option name="busStops" value="Antony church">Antony church</option>
                                                <option name="busStops" value="A.F.T">A.F.T</option>
                                                <option name="busStops" value="Murungapakkam">Murungapakkam</option>
                                                <option name="busStops" value="Pillaiyar Kovil">Pillaiyar Kovil</option>
                                                <option name="busStops" value="Ponniyaman Kovil">Ponniyaman Kovil</option>
                                                <option name="busStops" value="Vaanavil">Vaanavil</option>
                                                <option name="busStops" value="Naavalar">Naavalar</option>
                                                <option name="busStops" value="Vallalar School">Vallalar School</option>
                                                <option name="busStops" value="Oulgaret Sandhai">Oulgaret Sandhai</option>
                                                <option name="busStops" value="Iyyanar Kovil">Iyyanar Kovil</option>
                                                <option name="busStops" value="Latha Steels">Latha Steels</option>
                                                <option name="busStops" value="Sarathambal Kovil">Sarathambal Kovil</option>
                                                <option name="busStops" value="Kaatupalayam">Kaatupalayam</option>
                                                <option name="busStops" value="Anugraga">Anugraga</option>
                                                <option name="busStops" value="Reddiyarpalayam">Reddiyarpalayam</option>
                                                <option name="busStops" value="Cauvery Nagar">Cauvery Nagar</option>
                                                <option name="busStops" value="Ajith Nagar">Ajith Nagar</option>
                                                <option name="busStops" value="Pazhva Nagar">Pazhva Nagar</option>
                                                <option name="busStops" value="Kamachi">Kamachi</option>
                                                <option name="busStops" value="R.T.O">R.T.O</option>
                                                <option name="busStops" value="Nainarmandapam">Nainarmandapam</option>
                                                <option name="busStops" value="Edayarpalayam">Edayarpalayam</option>
                                                <option name="busStops" value="Thavalakuppam">Thavalakuppam</option>
                                                <option name="busStops" value="Reddychavadi">Reddychavadi</option>
                                                <option name="busStops" value="Ginger Hotel">Ginger Hotel</option>
                                                <option name="busStops" value="Bharathidasan College">Bharathidasan College</option>
                                                <option name="busStops" value="Gingee Salai">Gingee Salai</option>
                                                <option name="busStops" value="Law College">Law College</option>
                                                <option name="busStops" value="Uppalam Stadium">Uppalam Stadium</option>
                                                <option name="busStops" value="Uppalam Kallarai">Uppalam Kallarai</option>
                                                <option name="busStops" value="Kandhasamy House">Kandhasamy House</option>
                                                <option name="busStops" value="Mudaliyarpettai">Mudaliyarpettai</option>
                                                <option name="busStops" value="Ariyankuppam Police Station">Ariyankuppam Police Station</option>
                                                <option name="busStops" value="Boat House">Boat House</option>
                                                <option name="busStops" value="Poornakuppam">Poornakuppam</option>
                                                <option name="busStops" value="Sivaji Statue ">Sivaji Statue </option>
                                                <option name="busStops" value="Marapaalam ">Marapaalam </option>
                                                <option name="busStops" value="Muthiyalpet Police Station">Muthiyalpet Police Station</option>
                                                <option name="busStops" value="Anandha Ranga">Anandha Ranga</option>
                                                <option name="busStops" value="Saram">Saram</option>
                                                <option name="busStops" value="Balaji Theatre">Balaji Theatre</option>
                                                <option name="busStops" value="JeevaRukmani Theatre">JeevaRukmani Theatre</option>
                                                <option name="busStops" value="Raja Theatre">Raja Theatre</option>
                                                <option name="busStops" value="Old Bus Stand">Old Bus Stand</option>
                                                <option name="busStops" value="Ariyankuppam">Ariyankuppam</option>
                                                <option name="busStops" value="Rani Hospital">Rani Hospital</option>
                                                <option name="busStops" value="Kathirkamam">Kathirkamam</option>
                                                <option name="busStops" value="Shanmugapuram">Shanmugapuram</option>
                                                <option name="busStops" value="Mettupalayam">Mettupalayam</option>
                                                <option name="busStops" value="Moolakulam">Moolakulam</option>
                                                <option name="busStops" value="Oulgaret">Oulgaret</option>
                                                <option name="busStops" value="Jaya Nagar">Jaya Nagar</option>
                                                <option name="busStops" value="Kirumampakam">Kirumampakam</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                }

                            </FormWizard.TabContent>

                            <FormWizard.TabContent
                                title="Academic details"
                                icon={GraduationCapIcon()}
                            >
                                {(programme === "BPT" || programme === "Biomedical Sciences") &&
                                    <div>
                                        <Row style={{ textAlign: "start" }} className='mt-2'><strong>Details of School Examination:</strong></Row>
                                        <Row style={{ textAlign: "start" }} className='mt-2'>
                                            <Col xs={12} sm={6}><strong>Name of the Examination</strong></Col>
                                            <Col xs={12} sm={6}>HSC</Col>
                                        </Row>
                                        <Row style={{ textAlign: "start" }} className='mt-2'>
                                            <Col xs={12} sm={6}><strong>Month and Year of Passing</strong></Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Control
                                                    type='text'
                                                    {...register('hsc_Month_year_of_passing', { required: "This field is required." })}
                                                >
                                                </Form.Control>
                                                {
                                                    errors?.hsc_Month_year_of_passing &&
                                                    <Form.Text className="text-error" style={{ color: "red" }}>
                                                        {errors.hsc_Month_year_of_passing.message}
                                                    </Form.Text>
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: "start" }} className='mt-2'>
                                            <Col xs={12} sm={6}><strong>Name of School and Address</strong></Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Control
                                                    type='text'
                                                    as="textarea"
                                                    {...register('hsc_Name_address', { required: "This field is required." })}
                                                >
                                                </Form.Control>
                                                {
                                                    errors?.hsc_Name_address &&
                                                    <Form.Text className="text-error" style={{ color: "red" }}>
                                                        {errors.hsc_Name_address.message}
                                                    </Form.Text>
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: "start" }} className='mt-2'>
                                            <Col xs={12} sm={6}><strong>Total Percentage of Marks</strong></Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Control
                                                    type='number'
                                                    {...register('hsc_Total_percentage_of_marks',
                                                        {
                                                            required: "This field is required.",
                                                            pattern: {
                                                                value: /^(100|[1-9][0-9]?)$/,
                                                                message: "Percentage should be a number between 1 and 100"
                                                            }
                                                        })}
                                                >
                                                </Form.Control>
                                                {
                                                    errors?.hsc_Total_percentage_of_marks &&
                                                    <Form.Text className="text-error" style={{ color: "red" }}>
                                                        {errors.hsc_Total_percentage_of_marks.message}
                                                    </Form.Text>
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: "start" }} className='mt-2'>
                                            <Col xs={12} sm={6}><strong>Grade (for Overseas Students)</strong></Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Control
                                                    type='text'
                                                    {...register('overseasstudentgrade')}
                                                >
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: "start" }} className='mt-2 mb-4'>
                                            <Col xs={12} sm={6}><strong>Attempt in which Qualifying Examination is Passed</strong></Col>
                                            <Col xs={12} sm={6}>
                                                <Form.Group>
                                                    <Form.Check
                                                        inline
                                                        label="First"
                                                        type="radio"
                                                        value="First"
                                                        {...register('hsc_Attempt', { required: "This field is required." })}
                                                    >
                                                    </Form.Check>
                                                    <Form.Check
                                                        inline
                                                        label="Second"
                                                        type="radio"
                                                        value="Second"
                                                        {...register('hsc_Attempt', { required: "This field is required." })}
                                                    >
                                                    </Form.Check>
                                                    <Form.Check
                                                        inline
                                                        label="Third"
                                                        type="radio"
                                                        value="Third"
                                                        {...register('hsc_Attempt', { required: "This field is required." })}
                                                    >
                                                    </Form.Check>
                                                </Form.Group>
                                                {
                                                    errors?.hsc_Attempt &&
                                                    <Form.Text className="text-error" style={{ color: "red" }}>
                                                        {errors.hsc_Attempt.message}
                                                    </Form.Text>
                                                }
                                            </Col>
                                        </Row>

                                        <Divider />

                                        <Row style={{ textAlign: "start" }} className='mt-2'>
                                            <Col xs={12} sm={12}>
                                                <strong>Marks / Grade Obtained in the Qualifying Examination:</strong>
                                            </Col>
                                        </Row>
                                        <Row style={{ textAlign: "center" }} className='mt-2'>
                                            <Col xs={3} sm={3}><strong>Subject</strong></Col>
                                            <Col xs={3} sm={3}><strong>Marks Obtained</strong></Col>
                                            <Col xs={3} sm={3}><strong>Maximum Marks</strong></Col>
                                            <Col xs={3} sm={2}><strong>Percentage</strong></Col>
                                            <Col xs={3} sm={1}><strong>Action</strong></Col>
                                        </Row>
                                        {
                                            schoolExamData &&
                                            schoolExamData.map((item, index) => (
                                                <Row className="mt-2" key={item.id} style={{ textAlign: "center" }}>
                                                    <Col xs={3} sm={3}>
                                                        <Form.Control
                                                            type="text"
                                                            name="subject"
                                                            value={item.subject}
                                                            onChange={(event) => handleInputChange(event, index)}
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                    <Col xs={3} sm={3}>
                                                        <Form.Control
                                                            type="number"
                                                            name="obtainedMarks"
                                                            value={item.obtainedMarks}
                                                            onChange={(event) => handleInputChange(event, index)}
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                    <Col xs={3} sm={3}>
                                                        <Form.Control
                                                            type="number"
                                                            name="maximumMarks"
                                                            value={item.maximumMarks}
                                                            onChange={(event) => handleInputChange(event, index)}
                                                        />
                                                    </Col>
                                                    <Col xs={3} sm={2}>

                                                        {item.percentage} %

                                                    </Col>
                                                    <Col xs={3} sm={1}>
                                                        {schoolExamData.length - 1 !== index &&
                                                            <Icon.Trash
                                                                color="red"
                                                                size={25}
                                                                type="button"
                                                                onClick={() => removeExaminationItems(index)}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Delete Row"
                                                                style={{ marginRight: "10px" }}
                                                            />
                                                        }
                                                        {schoolExamData.length - 1 === index &&
                                                            <Icon.PlusCircle
                                                                color="blue"
                                                                size={25}
                                                                type="button"
                                                                onClick={() => appendExaminationItems()}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Add Row"
                                                            />
                                                        }
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                        <Row style={{ textAlign: "center" }} className='mt-2'>
                                            <Col xs={3} sm={3}><strong>Total</strong></Col>
                                            <Col xs={3} sm={3}><strong>{TotalObtained}</strong></Col>
                                            <Col xs={3} sm={3}><strong>{TotalMax}</strong></Col>
                                            <Col xs={3} sm={2}><strong>{TotalPercent} %</strong></Col>
                                            <Col xs={3} sm={1}><strong></strong></Col>
                                        </Row>
                                    </div>
                                }
                                {(programme === "MPT" || programme === "M.Sc" || programme === "M.Sc+Ph.D") &&

                                    <div>
                                        <Row style={{ textAlign: "start" }} className='mt-2'>
                                            <Col xs={12} sm={12}>
                                                <strong>UG Degree Details:</strong>
                                            </Col>
                                        </Row>

                                        <Row style={{ textAlign: "center" }} className='mt-2'>
                                            <Col xs={3} sm={2}><strong>Name of the Degree</strong></Col>
                                            <Col xs={3} sm={2}><strong>% of Marks / Grade</strong></Col>
                                            <Col xs={3} sm={2}><strong>Class Obtained</strong></Col>
                                            <Col xs={3} sm={2}><strong>Year of Completion</strong></Col>
                                            <Col xs={3} sm={3}><strong>College / University</strong></Col>
                                            <Col xs={3} sm={1}><strong>Action</strong></Col>
                                        </Row>

                                        {
                                            UG_Details &&
                                            UG_Details.map((item, index) => (
                                                <Row className="mt-2 mb-5" style={{ textAlign: "center" }} key={item.id}>
                                                    <Col xs={3} sm={2}>
                                                        <Form.Control
                                                            type='text'
                                                            {...register(`ugDegreeData.${index}.degree`, { validate: ugValidateDynamicaly })}
                                                        >
                                                        </Form.Control>
                                                        {
                                                            errors.ugDegreeData?.[index]?.degree &&
                                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                                {errors.ugDegreeData?.[index]?.degree?.message}
                                                            </Form.Text>
                                                        }
                                                    </Col>
                                                    <Col xs={3} sm={2}>
                                                        <Form.Control
                                                            type='text'
                                                            {...register(`ugDegreeData.${index}.percentageOfMarks`, { validate: ugValidateDynamicaly })}
                                                        >
                                                        </Form.Control>
                                                        {
                                                            errors.ugDegreeData?.[index]?.percentageOfMarks &&
                                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                                {errors.ugDegreeData?.[index]?.percentageOfMarks?.message}
                                                            </Form.Text>
                                                        }
                                                    </Col>
                                                    <Col xs={3} sm={2}>
                                                        <Form.Control
                                                            type='text'
                                                            {...register(`ugDegreeData.${index}.class`, { validate: ugValidateDynamicaly })}
                                                        >
                                                        </Form.Control>
                                                        {
                                                            errors.ugDegreeData?.[index]?.class &&
                                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                                {errors.ugDegreeData?.[index]?.class?.message}
                                                            </Form.Text>
                                                        }
                                                    </Col>
                                                    <Col xs={3} sm={2}>
                                                        <Form.Control
                                                            type='text'
                                                            {...register(`ugDegreeData.${index}.year`, { validate: ugValidateDynamicaly })}
                                                        >
                                                        </Form.Control>
                                                        {
                                                            errors.ugDegreeData?.[index]?.year &&
                                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                                {errors.ugDegreeData?.[index]?.year?.message}
                                                            </Form.Text>
                                                        }
                                                    </Col>
                                                    <Col xs={3} sm={3}>
                                                        <Form.Control
                                                            type='text'
                                                            {...register(`ugDegreeData.${index}.college_university`, { validate: ugValidateDynamicaly })}
                                                        >
                                                        </Form.Control>
                                                        {
                                                            errors.ugDegreeData?.[index]?.college_university &&
                                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                                {errors.ugDegreeData?.[index]?.college_university?.message}
                                                            </Form.Text>
                                                        }
                                                    </Col>
                                                    <Col xs={3} sm={1}>
                                                        {UG_Details.length - 1 !== index &&
                                                            <Icon.Trash
                                                                color="red"
                                                                size={25}
                                                                type="button"
                                                                onClick={() => removeUG_DegreeItems(index)}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Delete Row"
                                                            />
                                                        }
                                                        {UG_Details.length - 1 === index &&
                                                            <Icon.PlusCircle
                                                                color="blue"
                                                                size={25}
                                                                type="button"
                                                                onClick={() => appendUG_DegreeItems()}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title="Add Row"
                                                            />
                                                        }
                                                    </Col>
                                                </Row>
                                            ))
                                        }

                                        <Divider />
                                    </div>
                                }
                                <Row style={{ textAlign: "start" }} className='mt-4'>
                                    <Form.Group>
                                        <Form.Check
                                            inline
                                            label={
                                                <div>
                                                    <span>
                                                        <strong>
                                                            I {name} hereby solemnly and sincerely affirm that the statements made and information furnished in my application
                                                            form and also in all the enclosures here to submitted by me are TRUE. Should in however by found that any information furnished herein is untrue,
                                                            I realise that I am liable for criminal prosecution and agree to for go my seat, at any stage.
                                                        </strong>
                                                    </span>
                                                </div>
                                            }
                                            type="checkbox"
                                            value="checked"
                                            {...register('declaration', { required: "You must check to the declaration" })}
                                        >
                                        </Form.Check>
                                        {
                                            errors?.declaration &&
                                            <Form.Text className="text-error" style={{ color: "red" }}>
                                                {errors.declaration.message}
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                </Row>

                            </FormWizard.TabContent>

                            <FormWizard.TabContent
                                title="Documents upload"
                                icon={UploadIcon()}
                            >
                                <Row className="mt-1">
                                    <Col><strong>Documents Upload</strong></Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={12} sm={5}>
                                        <Form.Select
                                            onChange={docnameChange}
                                            ref={fileNameRef}
                                            name='documentName'
                                        >
                                            <option>Select Document Name</option>
                                            <option value="photo">Photo</option>
                                            {/*      <option value="Birth Certificate">Birth Certificate</option>
                                            <option value="10 th Mark Sheet">10 th Mark Sheet</option>
                                            <option value="12 th Mark Sheet">12 th Mark Sheet</option>
                                            <option value="Transfer Certificate">Transfer Certificate</option>
                                            <option value="Aadhar Card">Aadhar Card</option>
                                            <option value="Community Certificate">Community Certificate</option>
                                            <option value="Income Certificate">Income Certificate</option> */}
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} sm={5} style={{ textAlign: "center" }}>
                                        <input
                                            htmlFor="formFile"
                                            className="form-control"
                                            id="customFile"
                                            type="file"
                                            accept=".pdf, .jpeg, .jpg,.png"
                                            style={{ display: 'block' }}
                                            onChange={(e) => handleFileChange(e)}
                                            ref={fileInputRef}
                                        />
                                    </Col>
                                    <Col xs={12} sm={2}>
                                        <Button
                                            size='sm'
                                            onClick={docUpload}
                                            style={{ backgroundColor: "green" }}
                                        >
                                            <Icon.Upload /> Upload
                                        </Button>

                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={12} sm={2}></Col>
                                    <Col xs={12} sm={8}>
                                        <Table striped bordered hover className="mt-3 text-center">
                                            <thead>
                                                <tr>
                                                    <th>Sl.no</th>
                                                    <th>Document Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {docList && docList.length > 0 &&
                                                    docList.map((obj, index) => (
                                                        <tr key={obj._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{obj.documentName}</td>
                                                            <td>
                                                                <Icon.Eye
                                                                    style={{ marginRight: "20px" }}
                                                                    color="#0A69FB"
                                                                    size={25}
                                                                    type="button"
                                                                    onClick={() => viewDocument(obj)}
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="View Doc"
                                                                />
                                                                <Icon.Trash
                                                                    color="#B8250B"
                                                                    size={25}
                                                                    type="button"
                                                                    onClick={() => deleteDocument(obj)}
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="Delete Doc"
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col xs={12} sm={2}></Col>
                                </Row>
                            </FormWizard.TabContent>

                            <FormWizard.TabContent
                                title="Download all declarations"
                                icon={DownloadIcon()}
                            >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <List
                                        style={{ width: "50%" }}
                                        size="small"
                                        header={<strong>Declaration Forms</strong>}
                                        footer={
                                            <Button
                                                className="primary"
                                                type='button'
                                                size='sm'
                                                onClick={() => downloadAllDeclarationsInPDF()}
                                            >
                                                download & finish
                                            </Button>
                                        }
                                        bordered
                                        dataSource={declarationFormList}
                                        renderItem={(item) => <List.Item>{item}</List.Item>}
                                    />
                                </div>
                            </FormWizard.TabContent>

                        </FormWizard>
                    </Form>
                </Card>
            </Container>
            <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
           .finish-button{
          background-color: #4073F1;
          height:35px;
          width:139px;
          border: none;
          color: white;
          text-align: center;
          float:right;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          font-weight:600;
          text-align: center;
          cursor: pointer;
          margin-right: 10px;
          margin-left: 10px;
          border-radius: 3px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          transition: background-color 0.3s ease;
        }
        .next-button{
          background-color: #4073F1;
          height:36px;
          width:140px;
          border: none;
          color: white;
          text-align: center;
          float:right;
          float:right;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          font-weight:600;
          text-align: center;
          cursor: pointer;
          margin-right: 10px;
          margin-left: 10px;
          border-radius: 3px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          transition: background-color 0.3s ease;
        }

        .next-button:disabled {
          background: #dddddd;
        }

        .back-button{
          background-color: #4073F1;
          height:36px;
          width:140px;
          border: none;
          color: white;
          text-align: center;
           align-items: start;
          float:left;
          text-decoration: none;
          display: inline-block;
          font-size: 16px;
          font-weight:600;
          text-align: center;
          cursor: pointer;
          margin-right: 10px;
          margin-left: 10px;
          border-radius: 3px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          transition: background-color 0.3s ease;
        }
        .wizard-card-footer{
          margin-top: 30px;
        }
      `}</style>
        </>
    )
}

export default StudentRegistration_BPT_MPT